<template >
  <div class="expManager">
      <div class="logButton clearFix">
          <div class="export">
              <el-button class="themeButton" @click="showDialog">添加虚拟实验</el-button>
          </div>
          <div class="searchLab">
            <div class="searchContent">
                  <el-select  
                    filterable
                    remote
                    clearable
                    v-model="lab_id" 
                    placeholder="请搜索选择分类"
                    @change="getData" 
                    :remote-method="searchexpList" 
                    class="" >
                  <el-option
                    v-for="item in expList"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
            </div>
            <div class="searchContent">
                <el-select
                  v-model="school_id"
                  @change="handleSearchColleague"
                  filterable
                  remote
                  clearable
                  placeholder="请搜索选择学校"
                  :remote-method="getschoolList">
                  <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
            </div>
            <div class="info">
              <div class="uncheckNum">{{explabListTotal - total}}</div>
              <div class="uncheckText">个实验信息不完整 / 共</div>
              <div class="uncheckNum">{{explabListTotal}}</div>
              <div class="uncheckText">个实验</div>
              <el-button class="themeButton" @click="updateExpStatistics">计算时间</el-button>
              <el-button class="themeButton" @click="exportList">导出列表</el-button>
            </div>
          </div>  
          
          <div class="search">
              <!-- 搜索题目 -->
              <div class="searchContent">
                  <el-input placeholder="请输入虚拟实验名称或ID" clearable @clear="getData" v-model="searchContent" class="input-with-select" ></el-input>
              </div>
              <el-button class="themeButton" @click="handleSearchColleague">搜索</el-button>
          </div>
      </div>
      <!-- 题目列表 -->
      <el-card>
          <el-table
              ref="multipleTable"
              :data="explabList"
              tooltip-effect="dark"
              style="width: 100%">
                  <!-- 序号 -->
                  <el-table-column
                    type="index"
                    :index="indexMethod"
                    label="序号"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    label="ID"
                    prop="id">
                  </el-table-column>
                  <el-table-column
                    label="名称"
                    width="280">
                    <template slot-scope="scope">
                      <div class="expName" @click="editExp(scope.row.id)">{{scope.row.name}}</div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="类型"
                    width="70">
                    <template slot-scope="scope">
                        <div v-if="scope.row.sign == 0">APP</div>
                        <div v-if="scope.row.sign == 1">小游戏</div>
                        <div v-if="scope.row.sign == 2">PC</div>
                        <div v-if="scope.row.sign == 3">VR</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="CID"
                    prop="CID"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    label="通过人次"
                    width="80"
                    prop="census_total">
                  </el-table-column>
                  <el-table-column
                    label="建议时间"
                    prop="suggest_time">
                  </el-table-column>
                  <el-table-column
                    label="信息完整">
                    <template slot-scope="scope">
                      <i  v-if="scope.row.pic && scope.row.article_id && scope.row.logo && scope.row.step && scope.row.helpurl " class="el-icon-check"></i>
                      <i v-else class="el-icon-close"></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="开发者">
                    <template slot-scope="scope">
                      {{scope.row.username}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="负责人">
                    <template slot-scope="scope">
                      {{scope.row.moolsnetAdminName}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="学校">
                    <template slot-scope="scope">
                      {{scope.row.schoolName}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="价格">
                    <template slot-scope="scope">
                      <!-- <el-tag type="success">{{scope.row.cost}}</el-tag> -->
                      <el-input class="costInput" @blur="change(scope.row)" v-model="scope.row.cost"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="是否开放">
                    <template slot-scope="scope">
                      <el-switch
                        @change = "change(scope.row)"
                        v-model="scope.row.status"
                        active-color="#0D5570"
                        inactive-color="#CCCCCC">
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="帮助素材">
                    <template slot-scope="scope">
                      <div  v-if="scope.row.article_id" class="editArticle" @click="editArticle(scope.row.article_id,scope.row.articleTitle)">查看</div>
                      <div v-else class="">暂无</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="ilab">
                    <template slot-scope="scope">
                      <div  v-if="scope.row.virtual_simulation_id" class="editArticle" @click="editWeb(scope.row.virtual_simulation_id)">查看</div>
                      <div v-else class="">暂无</div>
                    </template>
                  </el-table-column>
                  <!-- 操作-->
                  <el-table-column
                    label="操作"
                    width="220" >
                      <template slot-scope="scope">
                          <!-- <div class="operatorItem floatLeft" @click="editExp(scope.row.id)">编辑</div> -->
                          <div class="operatorItem floatLeft" @click="delExpById(scope.row.id,scope.row.name)">删除</div>
                          <!-- <div class="staticItem floatLeft">统计</div> -->
                          <div class="operatorItem floatLeft" @click="goToStat(scope.row.id)">统计</div>
                          <div class="operatorItem floatLeft" @click="updateExpTop(scope.row.id,scope.row.is_top)">
                            <span v-if="scope.row.is_top == 0">置顶</span>
                            <span v-else>取消置顶</span>
                          </div>
                          
                      </template>
                  </el-table-column>
          </el-table>
          <!-- 题目分页 -->
          <el-pagination
              @current-change="getData"
              :current-page.sync="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="explabListTotal">
          </el-pagination>
      </el-card>
          <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="30%">
              <span  class="dialog-span">
                  <el-form ref="form" :model="form" label-width="80px">
                      <el-form-item label="分类id">
                          <el-input placeholder="请输入分类id" v-model="form.id"></el-input>
                      </el-form-item>
                      <el-form-item label="分类名称">
                          <el-input placeholder="请输入分类名称" v-model="form.name"></el-input>
                      </el-form-item>
                      <!-- <el-form-item label="包名">
                          <el-input placeholder="请输入包名" v-model="form.package"></el-input>
                      </el-form-item> -->
                      <el-form-item label="备注">
                          <el-input placeholder="请输入备注" v-model="form.des"></el-input>
                      </el-form-item>
                      <!-- <el-form-item label="素材">
                          <el-select
                          :disabled="form.helpurl!=''&& form.helpurl!=null"
                              v-model="form.article_id"
                              filterable
                              remote
                              clearable
                              :loading="loading"
                              placeholder="请搜索选择素材！"
                              :remote-method="getArticleList">
                              <el-option
                                  v-for="item in articleList"
                                  :key="item.id"
                                  :label="item.title"
                                  :value="item.id">
                              </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="帮助链接">
                          <el-input placeholder="请输入帮助链接" :disabled="form.article_id!=''&& form.article_id!=null" v-model="form.helpurl"></el-input>
                      </el-form-item> -->
                  </el-form>
              </span>
          <span
              slot="footer"
              class="dialog-footer">
              <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
              <el-button class="themeButton" @click="confirmAdd">确 定</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
  // 引入试题列表js
  import expManager from "@/assets/js/manager/exp/expManager.js";
  export default {
   ...expManager
  }
</script>
<style>
.expManager .el-table .el-table__cell {
  padding: .55vw 0 !important; 
}
.expManager .el-icon-check,.expManager .el-icon-close{
  font-size: 1vw;
  font-weight: 900;
text-align: center;
  color: #0D5570;
}
.expManager .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0D5570;
  color: #FFF;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
  @import "@/assets/css/manager/exp/expManager.scss";
</style>