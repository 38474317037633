import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const'
import {getDurantion} from '@/utils/function'
import API from '@/api/manager/exp/expManager.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import router from "@/router";
export default {
    // 页面名称
    name: "expManager",
    data() {
        return {
            // 虚拟实验分类详情
            form:{
                id:'',
                name:'',
                package:'',
                des:'',
                article_id:'',
                helpurl:''
            },
            // 信息完整总数
            total:'',
            // 分类id
            lab_id:'',
            expList:[],
            expPage:1,
            explabList: [],
            explabListTotal: 0,
            // 根据虚拟实验内容搜索
            searchContent: "",
            // 搜索文章
            articleList: [],
            currentPage: 1,
            pageSize: 10,
            exp_id: '',
            // 控制添加虚拟实验分类对话框的显示与隐藏
            dialogVisible: false,
            loading:true,
            title:'',
            schoolList:[],
            // 学校id
            school_id:'',
            AddFormData:{
                id:'',
                name:'',
                des:'',
                helpurl:'',
                lab_id:'',
                cost:'',
                outday:0,
                CID:'',
                status:'',
                pic:[],
                logo:'',
                sign:'',
                basics_total:'',
                basics_browse:'',
                create_uid:'',
            },
        }
    },
    created() {
        let self = this;
        self.searchContent = this.$router.currentRoute.query.search;
        self.currentPage = Number(this.$router.currentRoute.query.page)?Number(this.$router.currentRoute.query.page):1;
        self.lab_id = this.$router.currentRoute.query.lab_id;
        self.getData();
        // while(self.expPage < 6)
        // {
            self.getexpList();
            // self.expPage++
        // }
        self.getschoolList();
    },
    methods:
    {   
        goToStat(exp_id)
        {
            window.open("/#/manager/managerExpStat?hideMenu=1&exp_id="+exp_id);
            /*let self = this;
            self.$router.push({
                path: "/manager/managerStat",
                query: {
                    exp_id: exp_id
                },
            });//*/
        },
        updateExpTop(exp_id,is_top)
        {
            let self = this;
            let data = {
                exp_id:exp_id,
                is_top:is_top
            }
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.updateExpTop(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) 
                    {
                        self.getData();
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        exportList()
        {
            window.open('https://app.mools.net/backend/public/index.php/adminExp/exportExpToExcel');
        },

        updateExpStatistics()
        {
            let self = this;
            let exp_id = []
            self.explabList?.forEach((item)=>{
                exp_id.push(item.id)
            });
            let str = encryptCode({
                exp_id:exp_id.toString()
            })
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.updateExpStatistics(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.getData();
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        searchexpList(e)
        {
            this.expList = [];
            this.expPage =1;
            this.getexpList(e);
        },
        getschoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    if(result.code > 0 )
                    {
                        self.schoolList = result.list
                    }
                    // else
                    // {
                    //     Message.error('系统错误')
                    // }
                })                
        },

        editWeb(id)
        {
            window.open(`#/manager/editWebDetail?id=${id}&detail_id=${id}`)
        },

        editArticle(id,name)
        {
            window.open(`#/teacher/article/articleUpdate?article_id=${id}&name=${name}&hideMenu=1&type=0`)
        },

        getexpList(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e??'',
                page:self.expPage,
                pagesize:10
            };
            self.$router.push({
                path: "/manager/expManager",
                query: {
                    page: self.currentPage,
                    search: self.searchContent,
                    lab_id:self.lab_id?self.lab_id:''
                },
                });
            // 获取虚拟实验分类请求
            API.getExpLabList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.expList.push(...result.logList)
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        change(row)
        {
            let self = this;
            for (const key in self.AddFormData) 
            {
                self.AddFormData[key] = row[key]
            }
            let status = self.AddFormData.status ? 1 : 0
            let cost = self.AddFormData.cost;
            let id = self.AddFormData.id;
            let data = {
                id : id,
                cost : cost,
                status : status
            };
            // 添加/修改虚拟实验
            API.updateExpFontend(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        self.getData();
                        // self.$Tips({
                        //     // 消息提示内容
                        //     message: result.msg,
                        //     // 消息提示类型（success-成功,warning-警告/失败）
                        //     messageType: 'success',
                        //     displayTime: 1500
                        // })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        showDialog()
        {
            window.open('#/manager/expDetail');
        },

        getData() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: self.searchContent,
                    lab_id:self.lab_id,
                    school_id:self.school_id,
                    page:self.currentPage,
                    pagesize:self.pageSize
                };
                self.$router.push({
                    path: "/manager/expManager",
                    query: {
                        page: self.currentPage,
                        search: self.searchContent,
                        lab_id:self.lab_id
                    },
                });
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getExpList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            result.logList?.forEach((item)=>{
                                item.status = item.status == 1?true:false
                                // item.suggest_time = getDurantion(item.suggest_time)
                                item.suggest_time =  `${(item.suggest_time/60).toFixed(1)}分`
                            })
                            self.explabList = result.logList;
                            self.explabListTotal = result.number - 0;
                            self.total = result.total - 0;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        editExp(id)
        {
            window.open('#/manager/expDetail?id='+ id);
        },
        /**
         * @name: 删除虚拟实验分类
         * @author: lxy
         * @date: 20210908
         */
        delExpById(id,name)
        {
            let self = this;
            // 请求参数
            let data = {
                id:id
            };
            // 删除同事请求
            self.$confirm(`确认要删除${name}吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                API.delExpById(data)
                    .then((result) => {
                        if (result.code > 0) {
                            // Message.success('删除成功');
                            self.getData();
                            this.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        // else
                        // {
                        //     Message.error('系统错误');
                        // }
                    })
            }).catch(() => {
                // Message.info('已取消');        
            });
        },
        /**
         * @name: 获取修改虚拟实验分类数据
         * @author: lxy
         * @date: 20210908
         */
        getExpLabDataById(id)
        {
            let self = this;
            // 请求参数
            let data = {
                id: id,
            };
            // 修改虚拟实验分类数据请求
            API.getExpLabDataById(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        self.articleList.push({
                            id:result.info.article_id,
                            title:result.info.articleName,
                        })
                        self.form={...result.info};
                        self.title="修改虚拟实验分类"
                        self.dialogVisible = true
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        confirmAdd() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.form.id,
                    name:self.form.name,
                    // article_id:self.form.article_id,
                    // helpurl:self.form.helpurl,
                    des:self.form.des,
                };
                if(!self.form.id)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类id',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                if(!self.form.name)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类名称',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                // 添加/修改虚拟实验分类数据
                API.updateExpLabData(data)
                    .then((result) => {
                        if (result.code > 0) {
                            for (const key in self.form) {
                                self.form[key] = ''
                            }
                            self.dialogVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        getArticleList(e) {
            try {
                let self = this;
                let data = {
                    search: e
                }
                if (e) {
                    API.getArticleList(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.articleList = result.articleList;
                                self.loading = false
                            }
                            else {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },

        handleSearchColleague() 
        {
            let self = this;
            self.currentPage = 1;
            self.getData()
        },

        updateSortBy(id,sort_no)
        {
            let self = this;
            let data = {
                id: id,
                sort:sort_no
            }
            API.updateSortBy(data)
                .then((result) => {
                    if (result.code > 0) {
                        this.getData();
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else {
                        // Message.error('系统错误')
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        indexMethod(index) 
        {
            let currentPage = this.currentPage - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        }
};